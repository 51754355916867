<template>
  <div class="notification">
    <div class="search-wrapper">
      <a-form-model
        :model="filters"
        :layout="'inline'"
        @submit.prevent="handleSearch"
      >
        <a-form-model-item prop="title" label="名称">
          <a-input
            v-model="filters.title"
            type="text"
            placeholder="输入搜索"
            allowClear
            @change="
              () => {
                if (filters.title == '') delete filters.title;
              }
            "
          ></a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-space>
            <a-button
              icon="search"
              :loading="loading"
              type="primary"
              htmlType="submit"
            >
              搜索
            </a-button>
            <a-button
              icon="redo"
              :loading="loading"
              htmlType="reset"
              @click="handleReset"
            >
              重置
            </a-button>
          </a-space>
        </a-form-model-item>
      </a-form-model>
    </div>
    <notifiation-table
      :loading.sync="loading"
      :entity="entity"
      :filters="filters"
      :sorter="[
        { field: 'createTime', order: 'DESC' },
        { field: 'sequence', order: 'DESC' },
      ]"
    ></notifiation-table>
  </div>
</template>

<script>
import NotifiationTable from "./table";
const defaultEntity = {};
const defaultFilters = {};
export default {
  components: { NotifiationTable },
  data() {
    return {
      loading: false,
      entity: JSON.parse(JSON.stringify(defaultEntity)),
      filters: JSON.parse(JSON.stringify(defaultFilters)),
    };
  },
  methods: {
    handleSearch() {
      this.filters = JSON.parse(JSON.stringify(this.filters));
    },
    handleReset() {
      this.filters = JSON.parse(JSON.stringify(defaultFilters));
    },
  },
  mounted() {
    this.handleReset();
  },
};
</script>

<style>
</style>